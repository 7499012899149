import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home/";
import Products from "./pages/Products";

function App() {
  const GTM_CONTAINER_ID = "GTM-W4JC693G"; 

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_CONTAINER_ID });

  
  }, []);

  return (
    <Router>

        <div className="flex flex-col min-h-screen">
          <Navbar />
          <div className="flex-1">
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
          <Footer />
        </div>
    </Router>
  );
}


export default App;
