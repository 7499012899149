import React from 'react';

function Card({ 
    imgSrc, 
    altText, 
    title, 
    description, 
    link, 
    cardSize, 
    imgSize, 
    titleSize,
    textSize,
     
}) {
    return (
      <div className={`bg-white rounded-lg shadow-md flex flex-col items-center justify-center ${cardSize}`}>
        <img src={imgSrc} alt={altText} className={`${imgSize} mb-4 object-cover`} />
        <h3 className={`${titleSize} font-semibold text-gray-800 mb-2`}>{title}</h3>
        <p className={`${textSize} text-gray-600 mb-4 text-center`}>{description}</p>

      </div>
    );
}

export default Card;
