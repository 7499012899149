import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-zinc-800 p-4 sm:p-8 border-t-4 border-blue-500">
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="">
            <h3 className="font-bold text-xl mb-4 text-gray-300">
              Our Latest Work
            </h3>
            <div className="border-b border-dotted border-gray-300 mb-8"></div>
            <div className="flex items-center justify-center h-auto max-w-max border p-4 bg-white rounded">
              <Link to="/products#software-section">
                <img
                  className="w-full"
                  src="/Content/images/home/featuredproduct.jpg"
                  alt="Loki Gen 6 - 3D Replay Viewer"
                />
              </Link>
            </div>
          </div>
          <div className="">
            <h3 className="font-bold text-xl mb-4 text-gray-300">Navigate</h3>
            <div className="border-b border-dotted border-gray-300 mb-8"></div>
            <ul className="list-disc list-inside">
              <li>
                <a className="text-sm text-blue-500 hover:underline" href="/">
                  Home
                </a>
              </li>
              <li>
                <a className="text-sm text-blue-500 hover:underline" href="Products">
                  Products
                </a>
              </li>
              <li>
                <a className="text-sm text-blue-500 hover:underline" href="/About">
                  About
                </a>
              </li>
              <li>
                <a className="text-sm text-blue-500 hover:underline" href="/Contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="text-sm">
            <h3 className="font-bold text-xl mb-4 text-gray-300">Contact</h3>
            <div className="border-b border-dotted border-gray-300 mb-8"></div>
            <p className="text-gray-300">
              <strong>iTrack Solutions Inc.</strong>
              <br />
              #300, 340 Midpark Way SE
              <br />
              Calgary, AB T2X 1P1
              <br />
              <strong>Phone:</strong> (403) 873-2560 <b>alt:</b> (403) 813-5199
              <br />
              <strong>Email:</strong>{" "}
              <a
                className="text-blue-500 hover:underline"
                href="mailto:info@itracksolutions.com"
              >
                info@itracksolutions.com
              </a>
            </p>
          </div>
        </div>
        <div className="mt-8">
          <div className="text-center border-t pt-4 text-gray-400">
            <p>
              &copy; {new Date().getFullYear()} Integrated Tracking Solutions
              Inc. All rights reserved.
            </p>
            <p className="text-xs">
              Apple, the Apple logo, and iPhone are trademarks of Apple Inc.,
              registered in the U.S. and other countries. App Store is a service
              mark of Apple Inc.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
