import React from "react";
import Card from "../../components/Card";

function About() {
  return (
    <div>
      <div
        className="p-4 sm:p-8 mt-16 sm:mt-20"
        style={{
          backgroundImage:
            "url('/Content/images/theme/page-background-dark.png')",
        }}
      >
        <div className="container mx-auto max-w-7xl">
            <h1 className="text-3xl text-white font-semibold mt-2">About</h1>
        </div>
      </div>

      <div className="bg-white py-6">
        <div className="container mx-auto max-w-7xl">
          <div className="flex flex-col md:flex-row md:space-x-4">
            <div className="w-full md:w-1/2 mb-4 md:mb-0 p-4 md:p-0">
              <h3 className="text-2xl font-semibold mb-4">Our History</h3>
              <p className="mb-4">
                Integrated Tracking Solutions Inc. (iTrack Solutions) is based
                in Calgary, Alberta, Canada. The company was founded in 2002.
              </p>
              <p className="mb-4">
                iTrack Solutions is in the business of systems integration, and
                operates in the manner of an engineering services company. The
                systems the company provides all have asset tracking as their
                primary feature. This involves GPS tracking, mobile data
                communications, and processing/display software. These systems
                also address related mobile data applications such as status
                reporting and operator interaction.
              </p>
              <p className="mb-4">
                The company uses hardware and software components from
                mainstream and well established suppliers. Mobile data services
                are provided, in most cases, by the cellular service providers.
              </p>
              <p className="mb-4">
                The company is wholly owned by its management. The senior
                technical staff are highly experienced and qualified software
                developers. Together, the management and staff have over 30
                years of experience in mobile asset tracking. Most of the work
                is software development. Much of this is repeat business from
                key clients. The British Army has been the largest single
                customer.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src="Content/images/iTrack-logo-large.png"
                alt="Integrated Tracking Solutions Inc."
                className="w-full mb-4"
              />
            </div>
          </div>
          <div className="mt-6 p-4 md:p-0">
            <h3 className="text-2xl font-semibold mb-4">Loki System</h3>
            <p className="mb-4">
              The Loki System, developed by iTrack, is the company's core
              product. It was designed to handle the real time tracking, and
              fleet management requirements which are common to all large fleet
              operators. However, many of the company’s clients have unique
              requirements for the management and control of their mobile
              assets. Starting from the Loki software, iTrack provides systems
              which meet these specific requirements. This is done by:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Integration with legacy systems, combining real data with
                simulated data.
              </li>
              <li>
                Special applications development. Many of these features find
                their way back into the core Loki product.
              </li>
              <li>Gateway development.</li>
            </ul>
            <p className="mb-4">
              Software development is done in industry standard languages,
              notably Visual Studio .NET. MS SQL Server is a core component.
              Software is well documented and can be provided with source code
              in some cases.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: "url('/Content/images/theme/page-background.png')",
        }}
        className=""
      >
        <div className="py-6">
          <div className="container mx-auto max-w-7xl">
            <h2 className="text-3xl font-semibold border-b-2 border-gray-700 text-gray-700 mb-6">
              Our Services
            </h2>

          <div className="flex flex-wrap mt-4">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Card
                  imgSrc="/Content/images/theme/service-icon/diamond.png"
                  altText="gpsmap"
                  title="Design"
                  description="We work closely with our clients to design our products based on their specific needs."
                  link="/product1"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
                <Card
                  imgSrc="/Content/images/theme/service-icon/box.png"
                  altText="ruler"
                  title="Procurement"
                  description="We provide both the software and hardware required for your solution."
                  link="/product2"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
                <Card
                  imgSrc="/Content/images/theme/service-icon/ruler.png"
                  altText="box"
                  title="Implementation"
                  description="Agile SDLC allows us to produce products which meet our client's changing requirements."
                  link="/product3"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
                <Card
                  imgSrc="/Content/images/icons/coding64.png"
                  altText="box"
                  title="Software Development"
                  description="Our developers are capable of providing solutions which extend beyond the scope of asset tracking."
                  link="/product3"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
                <Card
                  imgSrc="/Content/images/icons/book64.png"
                  altText="box"
                  title="Training"
                  description="We offer in-house training for all of our products; this includes 1-on-1 time with the developers."
                  link="/product3"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
                <Card
                  imgSrc="/Content/images/icons/overflowing64.png"
                  altText="box"
                  title="Support"
                  description="We provide direct support for all of our products. On-site support is available, please contact us for more info."
                  link="/product3"
                  cardSize={"p-6 w-full"}
                  imgSize={"w-20 h-20"}
                  titleSize={"text-lg"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
