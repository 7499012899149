import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { FaDotCircle, FaRegDotCircle } from "react-icons/fa";

function Slider() {
  const slides = [
    {
      url: "Content/images/home/lokicrossplatform.png",
      title: "Cross-Platform Tracking Solutions",
      text: "iTrack Solutions' software is highly versatile, we offer many products to meet your business's specific needs.",
      backgroundImage: "Content/images/theme/homepage-slider/slider-bg4",
    },
    {
      url: "Content/images/home/lokiproductstack.png",
      title: "Special Applications Development",
      text: "iTrack Solutions specializes in custom software development, contact us to learn more.",
      backgroundImage: "Content/images/theme/homepage-slider/slider-bg1",
    },
    {
      url: "Content/images/home/frameworks.png",
      title: "Modern Cloud-Based Software",
      text: "All of our products are built using high quality components from Microsoft and open-source projects.",
      backgroundImage: "Content/images/theme/homepage-slider/slider-bg3",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const animationTimeout = useRef(null);


  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500); // 500ms is the duration of your animation
  };
  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500); // 500ms is the duration of your animation
  };

  useEffect(() => {
    setAnimate(true);

    // Clear previous animation timeout
    if (animationTimeout.current) {
      clearTimeout(animationTimeout.current);
    }

    // Set a new animation timeout
    animationTimeout.current = setTimeout(() => setAnimate(false), 500); // 500ms is the duration of your animation

    // Set up an interval to change slides automatically
    const slideInterval = setInterval(nextSlide, 5000); // Change slides every 5 seconds

    // Clean up the interval and timeout when the component unmounts
    return () => {
      clearInterval(slideInterval);
      clearTimeout(animationTimeout.current);
    };
  }, [currentIndex, nextSlide]);
  return (
    <div className="h-[500px] w-full mx-auto mt-16 relative group">
      {/* Flex container */}
      <div
        className="flex w-full h-full"
        style={{
          backgroundImage: `url(${slides[currentIndex].backgroundImage}.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        {/* Left Side - Text */}
        <div className="flex flex-col justify-center items-start w-1/2 p-4">
          <h2
            className={`text-3xl md:text-4xl lg:text-5xl text-white font-serif border-solid #53555c
              ${animate ? "slide-in-left" : ""}`}
          >
            {slides[currentIndex].title}
          </h2>
          <h3
            className={`font-semibold justify-center items-center text-white text-lg md:text-xl ${
              animate ? "slide-in-left" : ""
            } mt-4 md:mt-6`}
          >
            {slides[currentIndex].text}
          </h3>
        </div>

        {/* Right Side - Image */}
        <div className="flex justify-center items-center">
          <img
            src={slides[currentIndex].url}
            alt={slides[currentIndex].title}
            className={`duration-500 w-2/3 md:w-1/2 lg:w-3/4 p-0 ${animate ? "slide-in-right" : ""}`}
          />
        </div>

        {/* Left Arrow */}
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p- bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        {/* Right Arrow */}
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>

        {/* Slide Indicators */}
        <div className="flex justify-center py-2 absolute bottom-0 left-1/2 transform -translate-x-1/2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className="text-2xl m-2 cursor-pointer"
            >
              {slideIndex === currentIndex ? (
                <FaDotCircle style={{color: '#4F709C'}} />
              ) : (
                <FaRegDotCircle style={{color: '#4F709C'}} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;
