import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { FaDotCircle, FaRegDotCircle } from "react-icons/fa";

function ImageSlider({ slides = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const modalRef = useRef(null);

  const handleKeydown = (e) => {
    if (e.key === "Escape") {
      closeModal();
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeydown);
    }

    return () => document.removeEventListener("keydown", handleKeydown);
  }, [isModalOpen]);

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    setCurrentIndex(isLastSlide ? 0 : currentIndex + 1);
  }, [currentIndex, slides.length]);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    setCurrentIndex(isFirstSlide ? slides.length - 1 : currentIndex - 1);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 5000);
    return () => clearInterval(slideInterval);
  }, [currentIndex, nextSlide]);

  return (
    <div className="max-w-xl h-80 w-full m-auto relative group border rounded border-gray-300 shadow-md p-1 overflow-hidden">
      <div
        className="w-full h-full flex transition-transform duration-500 flex-wrap-nowrap"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${slide.backgroundImage})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-full flex-none cursor-pointer"
            onClick={() => openModal(slide.modalImage)}
          />
        ))}
      </div>

      {/* Left Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>

      {/* Right Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>

      {/* Slide Indicators */}
      <div className="flex justify-center py-2 absolute bottom-0 left-1/2 transform -translate-x-1/2">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl m-2 cursor-pointer"
          >
            {slideIndex === currentIndex ? (
              <FaDotCircle style={{ color: "#4F709C" }} size={20} />
            ) : (
              <FaRegDotCircle style={{ color: "#4F709C" }} size={20} />
            )}
          </div>
        ))}
      </div>

      {/* Modal Section */}
      {isModalOpen && modalImage && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="bg-black bg-opacity-50 w-full h-full absolute"
            onClick={closeModal}
          ></div>
          <div
            ref={modalRef}
            className="bg-white p-5 relative z-10 max-w-full w-[90%] h-[90%] md:w-4/5 md:h-4/5 overflow-y-auto mx-auto rounded"
          >
            <button onClick={closeModal} className="absolute top-0 right-0 p-2">
              Close
            </button>
            <img
              src={modalImage}
              alt="Modal Content"
              className="max-w-full h-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ImageSlider;
