import React from "react";

function Tracking() {
  return (
    <div
      style={{
        backgroundImage:
          "url('/Content/images/theme/page-background-dark.png')",
      }}
      className="flex-col justify-between items-start p-4 md:p-10 bg-black text-white w-full sm:px-6 md:px-80 mx-auto"
    >
      <div className="border-b-2">
        <h2 className="flex-1 text-4xl font-bold text-white">
          Tracking Devices
        </h2>
      </div>
      <h3 className="mt-3 text-xl font-bold">History</h3>
      <p className="mt-3">
        A GPS tracker is a GPS receiver connected to a mobile data transceiver.
        Early examples consisted of a GPS receiver, interfaced to a modem which
        was connected to a VHF radio. Today, they are nearly all self contained
        units, working through the 3G/4G cell networks. Some examples, designed
        for operation in very remote regions, operate through satellite
        communications networks. The main consideration is the operating area.
        This determines the communications network choice. In the case of the
        cell networks, which carrier has the coverage and service in the
        operating area. The next consideration is what is being tracked? It may
        be an individual, it may be a vehicle, or it may be some other type of
        mobile asset. This determines the form factor of the GPS tracker.
        Another consideration is what other data communication requirements does
        the unit have to address? Laptop connectivity is a common requirement.
        Status reporting of other vehicle systems is another one. This
        determines what features are required. Finally, what are the operating
        conditions? This determines the how “industrial” the product has to be.
        ITrack has a considerable amount of experience with a variety of
        different GPS tracker products. Contact us directly for advice.
      </p>
      <div className="flex flex-col md:flex-row justify-between items-start p-2 md:p-10">
        <img
          className="max-h-img max-w-img rounded-md mb-4 md:mb-0"
          src="/Content/images/products/trackers/modem.jpg"
          alt="gpsModem"
        ></img>
        <div className="flex-1 flex flex-col p-2 md:p-5">
          <h2 className="text-xl mb-2 font-bold">GPS Modems</h2>{" "}
          <p className="">
            The tracker unit consists of a cellular modem with a built-in GPS
            receiver. They can be programmed to report their GPS positions at
            time and distance based intervals. They can function as standalone
            GPS trackers, but they are more commonly used to connect laptop
            computers to the internet or corporate intranet. These units are
            typically provided with Ethernet, USB, and serial connectors. Most
            have input/output ports for reporting on/off events from the
            vehicle. Some units are Bluetooth enabled. Some also have WiFi
            capability, in which case they function as wireless routers
            supporting any nearby WiFi equipped laptops. With 3G, 4G, and LTE
            cell networks, this is a very useful feature. GPS modems are
            recommended when they can be permanently installed in the vehicles,
            with external GPS and cell antennas. This work is best done by a
            radio shop, and the end result is very good long term reliability.
            For operation in fringe coverage areas, they can be operated with
            high gain antennas and antenna boosters.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start p-2 md:p-10">
        <img
          className="max-h-img max-w-img rounded-md mb-4 md:mb-0"
          src="/Content/images/products/trackers/smartphone.jpg"
          alt="Smart Phone"
        ></img>
        <div className="flex-1 flex flex-col p-2 md:p-5">
          <h2 className="text-xl mb-2 font-bold">Smart Phones</h2>{" "}
          <p>
            These devices function as trackers if they are GPS equipped, as most
            of them are. We provide software which captures the GPS position and
            sends it to the tracking server via the cell network and the
            internet. The GPS reporting intervals are a user setting. They
            remain fully functional in every respect.
          </p>
          <p>
            These tracker types work well if they are permanently assigned to an
            individual, who also uses them for their designed purpose (they will
            be with the individual, they will be turned on, and they will be
            charged). There are several ways to link a smart phone to a vehicle.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start p-2 md:p-10">
        <img
          className="max-h-img max-w-img rounded-md mb-4 md:mb-0"
          src="/Content/images/products/trackers/laptoptracker.jpg"
          alt="Laptop Tracker"
        ></img>
        <div className="flex-1 flex flex-col p-2 md:p-5">
          <h2 className="text-xl mb-2 font-bold">Loki Laptop Tracker</h2>{" "}
          <p>
            Many laptops have built-in 3G/4G modules. Many also have built-in
            GPS. All current laptops have USB ports which can connect to and
            power an external GPS.
          </p>
          <p>
            We provide Loki Laptop Tracker software. The software captures the
            GPS position and sends it to the tracking server via the cell
            network and the internet. Functionally, it is similar to the phone
            tracker software described above. This is an attractive option
            because it eliminates the need for a GPS modem, with a significant
            cost saving.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start p-2 md:p-10">
        <img
          className="max-h-img max-w-img rounded-md mb-4 md:mb-0"
          src="/Content/images/products/trackers/hardenedtracker.jpg"
          alt="Hardened Tracker"
        ></img>
        <div className="flex-1 flex flex-col p-2 md:p-5">
          <h2 className="text-xl mb-2 font-bold">Hardened Trackers</h2>{" "}
          <p>
            These trackers are GPS modems which are mounted internally in metal
            cases. The GPS and cell antennas are mounted externally on the
            cases. Power and data ports are industrial design. The tracker units
            are sealed against water and dust. They are provided with mounting
            hardware which is specific to the vehicle type. Once an installation
            “design” has been finalized, these units can be installed and
            removed in minutes.
          </p>
          <p>
            Hardened trackers are ideal for short term tracking requirements
            where the need for performance and reliability is also high.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start p-2 md:p-10">
        <img
          className="max-h-img max-w-img rounded-md mb-4 md:mb-0"
          src="/Content/images/products/trackers/wearable_plbs.png"
          alt="Personal Locator Beacons"
        ></img>
        <div className="flex-1 flex flex-col p-2 md:p-5">
          <h2 className="text-xl mb-2 font-bold">
            Personal Locator Beacons (PLB)
          </h2>{" "}
          <p>
            Some PLB devices provide tracking via satellite networks where
            cellular coverage is inadequate. Many work alone safety programs
            require the use of PLB devices for situations where reliable
            tracking is needed.
          </p>
          <p>
            These devices provide added safety features such as: SOS emergency
            button, location check-in capability, custom response messages,
            wearable tracking devices for elderly and more.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Tracking;
