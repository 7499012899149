import React, { useEffect } from "react";
import Faq from "./components/Faq";
import Loki from "./components/Loki";
import Software from "./components/Software";
import Tracking from "./components/Tracking";

function Products() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div>

        <div id="software-section">
        <Software />
        </div>
        <div id="loki-section">
        <Loki />
        </div>
        <div id="faq-section">
        <Faq />
        </div>
        <div id="tracking-section">
        <Tracking />
        </div>
     
    </div>
  );
}

export default Products;
