import React from "react";
import { Link } from "react-router-dom";
import Slider from "../../components/Slider";

function Home() {
  return (
    <div>
      <Slider />
      <div className="bg-gray-100 py-8">
        <div className="container mx-auto max-w-7xl">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
              <img
                src="/Content/images/icons/gpsmap.png"
                alt="gpsmap"
                className="mb-4"
              ></img>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Mobile Asset Tracking
              </h3>
              <p className="text-gray-600 mb-4">
                Mobile asset tracking is the core business of iTrack Solutions;
                our staff has over 25 years of industry experience.
              </p>
              <a
                href="/about"
                className="inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Read more
              </a>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
              <img
                src="/Content/images/theme/service-icon/ruler.png"
                alt="ruler"
                className="mb-4"
              ></img>

              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Custom Solutions
              </h3>
              <p className="text-gray-600 mb-4 text-center">
                Many features included in our software are the direct result of
                collaboration with our customers.
              </p>
              <Link
                to="/products#faq-section"
                className="inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Read more
              </Link>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center">
              <img
                src="/Content/images/theme/service-icon/box.png"
                alt="box"
                className="mb-4"
              ></img>
              <h3 className="text-xl font-semibold text-center text-gray-800 mb-2">
                Flexible Deployment
              </h3>
              <p className="text-gray-600 mb-4 text-center">
                iTrack offers a variety of deployment options, from hosted
                services to on-premises deployment.
              </p>
              <Link
                to="/products#faq-section"
                className="inline-block bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage:
            "url('/Content/images/theme/page-background-dark.png')",
        }}
        className=" py-4"
      >
        <div className="container mx-auto text-center">
          <p className="text-lg text-white inline-block mr-2">
            Start tracking your assets with a free demo!
          </p>
          <a
            href="/contact"
            className="inline-block px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-gray-700"
          >
            Contact Us
          </a>
        </div>
      </div>

      <div className="bg-gray-100 py-8">
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Use Cases
          </h2>
          <div className="border-b-2 border-gray-300 mb-8"></div>
          <div className="flex flex-wrap justify-start">
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Fleet Tracking
              </h3>
              <p className="text-gray-600">
                Real-Time fleet tracking is often a primary requirement. Our
                software is capable of handling a large amount of incoming data
                which can be displayed in real-time on a variety of platforms.
              </p>
            </div>
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                CANbus Integration
              </h3>
              <p className="text-gray-600">
                We can create custom applications to view and distribute data
                collected from your CANbus enabled fleet. Gain insight into
                daily operations with custom designed real-time reports.
              </p>
            </div>
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Situation Awareness
              </h3>
              <p className="text-gray-600">
                Loki Gen 6 offer users a clear, real-time view of their assets.
                This enables more effective decision making and reduced
                accidents.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage: "url('/Content/images/theme/page-background.png')",
        }}
        className=" py-8"
      >
        <div className="container mx-auto max-w-7xl">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Products
          </h2>
          <div className="border-b-2 border-gray-300 mb-8"></div>
          <div className="flex flex-wrap justify-start">
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Loki Gen 6
              </h3>
              <p className="text-gray-600">
                Loki Gen.6 is a multi-platform cloud-based product, introduced
                late in 2014. This version supports high resolution web-based
                mapping with terrain with advanced communication features.
              </p>
              <Link
                to="/products#loki-section"
                className="mt-2 inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Learn More
              </Link>
            </div>
            <div className="w-full md:w-1/4 px-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Client Software
              </h3>
              <p className="text-gray-600">
                iTrack Solutions Inc. crafts bespoke software solutions that
                elevate operational efficiency. Successfully deployed across
                diverse sectors, our products exemplify innovation and
                client-centric excellence.
              </p>
              <Link
                to="/products#software-section"
                className="mt-2 inline-block px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
