import React from 'react';
import { useLocation } from "react-router-dom";

function Navbar() {
    const location = useLocation();

    return (
        <nav className="bg-white shadow-lg fixed top-0 w-full z-50 border-b-4 border-blue-500">
            <div className="container mx-auto flex items-center justify-start p-2">
                {/* Logo Section */}
                <div className="flex items-center mr-4">
                    <a href="/" className="flex items-center">
                        <img 
                            id="site-logo" 
                            className="logo h-17 w-auto" 
                            src="Content/images/iTrack-logo.png" 
                            alt="Integrated Tracking Solutions Inc. (iTrack Solutions)" 
                        />
                    </a>
                </div>
        
                {/* Navigation Links */}
                <div className="flex space-x-4">
                    <a href="/" className={`text-gray-700 hover:text-gray-900 ${location.pathname === '/' ? 'font-bold' : ''}`}>Home</a>
                    <a href="/products" className={`text-gray-700 hover:text-gray-900 ${location.pathname === '/products' ? 'font-bold' : ''}`}>Products</a>
                    <a href="/about" className={`text-gray-700 hover:text-gray-900 ${location.pathname === '/about' ? 'font-bold' : ''}`}>About</a>
                    <a href="/contact" className={`text-gray-700 hover:text-gray-900 ${location.pathname === '/contact' ? 'font-bold' : ''}`}>Contact</a>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
