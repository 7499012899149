import { Disclosure } from "@headlessui/react";
import React from "react";

function Faq() {
  const faqTitleRef = React.useRef(null);

  return (
    <div
      style={{
        backgroundImage: "url('/Content/images/theme/page-background.png')",
      }}
      className="py-8 px-4 sm:px-6 md:px-8 lg:px-12"
    >
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-2 sm:px-6 md:px-6 lg:px-8">
            <h3 ref={faqTitleRef} className="text-2xl font-bold mt-0 mb-4">
              Frequently Asked Questions
            </h3>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    What type of custom software development is available?
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <p>
                      While iTrack specializes in asset tracking software, we
                      often provide solutions which extend outside the scope of
                      asset tracking. Please{" "}
                      <a
                        href="/Contact"
                        className="text-blue-500 hover:underline"
                      >
                        contact us
                      </a>{" "}
                      for more information!
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    What are the different deployment options available?
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <p>
                      <b>Hosted Service</b>
                      <br />
                      The server components will be hosted from our office in
                      Calgary, AB, Canada. All company data is kept
                      confidential, we recommend this solution for
                      small-businesses.
                      <br />
                      <br />
                      <b>Enterprise Installation</b>
                      <br />
                      The server components will be installed on-site to your
                      premises, we recommend this solution for large businesses
                      with pre-existing infrastructure.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    How many concurrent users does your software support?
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <p>
                      Loki software has no specific limitation on concurrent
                      connections, we are only limited by the available
                      bandwidth and server infrastructure.
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    Where are the custom maps stored?
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <p>
                      <b>Loki Gen 6</b>
                      <br />
                      Custom maps can either be provided from a online mapping
                      service, or hosted privately on the Loki Gen 6 server.
                      <br />
                      <br />
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    Can I provide my own tracking hardware?
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <p>
                      If you have hardware capable of sending TAIP or NMEA
                      messages, it is more than likely that we will be able to
                      support your device. Please <a href="/">contact us</a> for
                      more information!
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            {/* ... Repeat for other questions ... */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
