import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

function ContactPage() {
  const defaultIcon = new L.Icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41], // Size of the icon
    shadowSize: [41, 41], // Size of the shadow
    iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
    shadowAnchor: [13, 41], // The same for the shadow
    popupAnchor: [0, -41], // Point from which the popup should open relative to the iconAnchor
  });

  return (
    <div>
      <div
        className="p-4 sm:p-8 mt-16 sm:mt-20"
        style={{
          backgroundImage:
            "url('/Content/images/theme/page-background-dark.png')",
        }}
      >
        <div className="container mx-auto max-w-7xl">
          <h1 className="text-3xl text-white font-semibold mt-2">Contact</h1>
        </div>
      </div>
      <div className="container mx-auto px-4 mt-10 max-w-7xl">
        <div className="flex">
          <div className="flex-1 pr-4">
            <div className="h-96 w-full mb-4">
              <MapContainer
                center={[50.90851, -114.063071]}
                zoom={15}
                scrollWheelZoom={false}
                style={{ height: "100%", width: "100%", zIndex: 20 }} // Ensure the map fills its container
              >
                <TileLayer
                  url="//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[50.90851, -114.063071]} icon={defaultIcon}>
                  <Popup>
                    iTrack Solutions Inc.
                    <br />
                    #300, 340 Midpark Way SE <br />
                    Calgary, AB
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
            <div className="">
              <b>iTrack Solutions Inc.</b>
              <p>
                #300, 340 Midpark Way SE <br />
                Calgary, AB T2X 1P1
              </p>
              <p>
                <b>Phone:</b> (403) 873-2560 <b>alt:</b> (403) 813-5199
              </p>
              <p>
                <b>Email:</b>{" "}
                <a
                  href="mailto:info@itracksolutions.com"
                  className="text-blue-600"
                >
                  info@itracksolutions.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
