import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import Card from "../../../../components/Card";
import ImageSlider from "../../../../components/ImageSlider";

function Loki() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex flex-col items-center">
      <div
        className="bg-center bg-cover h-20 md:h-28 lg:h-28 w-full flex items-start justify-center"
        style={{
          backgroundImage:
            "url('/Content/images/products/product_heading.jpg')",
        }}
      >
        <div className="max-w-7xl w-full pl-0">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white p-4 sm:p-6 md:p-8">
            The Loki System
          </h1>
        </div>
      </div>

      {/* Sections */}
      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <ImageSliderSection
          slides={[
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_aerial_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_aerial.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_birdseye_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_birdseye.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_cesium3d_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_cesium3d.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_military.png",
              modalImage: "/Content/images/products/gen6/livemap_military.png",
            },
          ]}
          actionButton={{
            label: "Watch Replay Viewer in Action",
            link: "https://www.youtube.com/watch?v=NqPIyVSit4M",
          }}
        />

<TextSection
  title="Loki Gen 6"
  paragraphs={[
    {
      content: `Loki Gen.6 is ideally suited for military and para-military training
        operations, particularly for small units such as infantry reserve
        regiments. These units have a need for realistic and high quality
        training, but they have to operate within narrow time and cost
        constraints. Para military training requirements are very similar.`
    },
    {
      content: (
        <>
          We recently completed a project for the Canadian Army, under the
          Build in Canada Innovation Program. This project was called
          <strong>
            "Tracking and Simulation for Small Unit Collective Training".
          </strong>
        </>
      )
    },
    {
      content: "The features of this project were:"
    }
  ]}
  list={[
    "The trackers were smartphones with extra battery packs, camo cases.",
    "Training areas - not restricted to existing army training ranges.",
    "Supports both Blue Force and Red Force participation.",
    "APP-6a NATO symbology.",
    "Status changes can be added, with notification to participants.",
    "Either force can introduce simulation elements, notional forces.",
    "Participants, observers can be anywhere with internet access.",
    "Can also be used for training exercises without troops.",
    "Replay feature can be used for after action reviews",
    "User inputs can be shared, and archived, through a forum feature."
  ]}
/>

      </section>

      <div
        style={{
          backgroundImage: "url('/Content/images/theme/page-background.png')",
        }}
        className="w-full py-8 md:px-100 sm:px-10 px-4"
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <Card
              imgSrc="/Content/images/icons/stack.png"
              altText="stack"
              title="High-Resolution Maps"
              description="Web based mapping offers greater resolution, up to date imagery, and world-wide mapping. Loki Gen 6 supports multiple map layers, including custom layers."
              link="/product1"
              cardSize={"p-6 w-full"}
              imgSize={"sm:w-24 w-20 h-20 sm:h-24"}
              titleSize={"text-lg"}
            />
            <Card
              imgSrc="/Content/images/icons/speech.png"
              altText="speech"
              title="Improved Communication"
              description="Loki Messenger provides users with real-time text and peer-to-peer video chat functionality, and we also offer an integrated discussion board."
              link="/product2"
              cardSize={"p-6 w-full"}
              imgSize={"w-32 h-32"}
              titleSize={"text-lg"}
            />
            <Card
              imgSrc="/Content/images/icons/cogwheel.png"
              altText="cog wheel"
              title="Highly Customizable"
              description="With our experience as a systems integration company, we are able to implement a seemingly unlimited number of additional features as needed."
              link="/product3"
              cardSize={"p-6 w-full"}
              imgSize={"w-32 h-32"}
              titleSize={"text-lg"}
            />
            <Card
              imgSrc="/Content/images/icons/download.png"
              altText="download"
              title="Immediate Updates"
              description="Utilizing cloud-based software allows us to push application updates and introduce new features without downtime or any effort on your behalf."
              link="/product3"
              cardSize={"p-6 w-full"}
              imgSize={"w-32 h-32"}
              titleSize={"text-lg"}
            />
          </div>
        </div>
      </div>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <ImageSliderSection
          slides={[
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_custommap_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_custommap.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_custommap2_thumb.jpg",
              modalImage:
                "/Content/images/products/gen6/livemap_custommap2.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_messenger_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_messenger.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/forum_home_thumb.png",
              modalImage: "/Content/images/products/gen6/forum_home.png",
            },
            {
              backgroundImage:
                "/Content/images/products/gen6/livemap_videofeed_thumb.jpg",
              modalImage: "/Content/images/products/gen6/livemap_videofeed.jpg",
            },
          ]}
        />

        <TextSection
          title="Enhanced Live View"
          paragraphs={[
            {
              subtitle: null,
              content: (
                <>
                  3D Terrain <small>Using WebGL</small>
                </>
              ),
            },
            {
              subtitle: null,
              content:
                "View your assets mapped onto high resolution terrain models with Cesium 3D.",
            },
            {
              subtitle: "Custom Maps",
              content:
                "Overlay your own custom map layers, adjust opacity of map layers for new insight and improved situational awareness.",
            },
            {
              subtitle: "Loki Messenger + Discussion Board",
              content:
                "Included with Loki Gen 6 is an integrated text and video chat client built on XMPP and WebRTC. We also provide an optional integrated discussion board.",
            },
            {
              subtitle: "Live Video Streams",
              content:
                "View live video streams directly from within the live map page.",
            },
          ]}
        />
      </section>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <TextSection
          title="System Management"
          paragraphs={[
            {
              subtitle: "User Management",
              content:
                "Manage your tracked assets and users directly from the built-in administrative console. Grant permissions and assign roles to other users, integrate directly with your Active Directory.",
            },
            {
              subtitle: "Tracker Management",
              content:
                "Quickly assign trackers to assets, add new tracking devices to the system and manage existing devices. Loki Gen 6 supports GPS tracking devices from a variety of vendors, includes support for Android and BlackBerry devices.",
            },
            {
              subtitle: null,
              content: (
                <a
                  href="#tracking-section"
                  className="text-blue-500 hover:text-blue-800"
                >
                  Click to view supported trackers.
                </a>
              ),
            },
          ]}
        />

        {/* Image Section */}
        <div className="flex-1 max-w-4xl">
          {/* Adjust width and padding as needed */}
          <img
            src="/Content/images/products/gen6/manage_groupasset.png"
            alt="Group Assets"
            onClick={() => setIsModalOpen(true)}
            className="cursor-pointer border rounded border-gray-300 shadow-md p-1" // To indicate it's clickable
          />
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Overlay */}
            <div
              className="bg-black bg-opacity-50 w-full h-full absolute"
              onClick={() => setIsModalOpen(false)}
            ></div>
            {/* Modal content */}
            <div className="bg-white p-5 relative z-10 w-4/5 h-4/5 overflow-y-auto mx-auto rounded">
              {/* 4. Add a close button or mechanism */}
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-0 right-0 p-2"
              >
                Close
              </button>
              <img
                src="/Content/images/products/gen6/manage_groupasset.png"
                alt="Group Assets"
                className="max-w-full h-auto"
              />
            </div>
          </div>
        )}
      </section>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        {/* Slider Section */}
          <ImageSliderSection
            slides={[
              {
                backgroundImage:
                  "/Content/images/products/gen6/replay_cesium3d_thumb.jpg",
                modalImage: "/Content/images/products/gen6/replay_cesium3d.jpg",
              },
              {
                backgroundImage:
                  "/Content/images/products/gen6/report_results_thumb.png",
                modalImage: "/Content/images/products/gen6/report_results.png",
              },
              {
                backgroundImage:
                  "/Content/images/products/gen6/report_distancemap_thumb.jpg",
                modalImage:
                  "/Content/images/products/gen6/report_distancemap.jpg",
              },
              {
                backgroundImage:
                  "/Content/images/products/gen6/report_stopmap_thumb.jpg",
                modalImage: "/Content/images/products/gen6/report_stopmap.jpg",
              },
            ]}
          />
        <TextSection
          title="Archive Access"
          paragraphs={[
            {
              subtitle: "Dynamic Report Creator",
              content:
                "Create customized reports using our dynamic report builder reports can be viewed using a variety of view modes.",
            },
            {
              subtitle: "3D Replay Viewer",
              content:
                "Playback archived data using our interactive replay generator, replays are stored on the server and can be shared with other users. Archived data is interpolated and played back using Cesium 3D.",
            },
          ]}
        />
      </section>

      <div className="container mx-auto px-4 sm:px-6 md:px-12 lg:px-16 py-2">
        <div className="flex flex-wrap">
          <div className="w-full px-2 sm:px-4 md:px-6 lg:px-8">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between items-center w-full text-left py-3 border-2 rounded bg-gray-50 p-2 text-blue-500">
                    + Technical Specifications
                  </Disclosure.Button>
                  <Disclosure.Panel className="py-2 border-2 rounded bg-gray-50 p-4">
                    <div className="space-y-1">
                      <p className="text-xs">
                        <strong className="font-bold">
                          Database Provider:
                        </strong>{" "}
                        MS SQL Server
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">
                          Programming Languages:
                        </strong>{" "}
                        C# 5.0, Javascript
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">Chat Protocol:</strong>{" "}
                        XMPP for text - WebRTC for video
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">Map Providers:</strong>{" "}
                        Bing Maps, OpenStreetMaps, ESRI
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">Map Engines:</strong> Bing
                        Maps AJAX, Leaflet, Cesium
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">
                          Server Requirements:
                        </strong>{" "}
                        Windows Server 2003+
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">Web Framework:</strong>{" "}
                        ASP.NET MVC
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">
                          Client Requirements:
                        </strong>{" "}
                        Modern Web Browser (Chrome, Firefox, etc.)
                      </p>
                      <p className="text-xs">
                        <strong className="font-bold">
                          Supported Trackers:
                        </strong>{" "}
                        <a
                          href="#tracking-section"
                          className="text-blue-500 hover:underline"
                        >
                          Click Here
                        </a>
                      </p>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundImage:
            "url('/Content/images/theme/page-background-dark.png')",
        }}
        className="py-4 w-full"
      >
        <div className="container mx-auto text-center">
          <p className="text-lg text-white inline-block mr-2">
            Start tracking your assets with a free demo!
          </p>
          <a
            href="/contact"
            className="inline-block px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-gray-700"
          >
            Contact Us
          </a>
        </div>
      </div>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <TextSection
          title="Custom Telematics Solutions"
          paragraphs={[
            {
              subtitle: "Real-time Business Insight",
              content: (
                <>
                  Go beyond GPS tracking by including business critical data
                  collection as part of your custom solution. Create unique
                  views of your data, gain new insight and add more value to
                  your product.
                </>
              ),
            },
            {
              subtitle: "CAN Bus + OBD-II Integration",
              content: (
                <>
                  Utilize existing features in your equipment to simplify daily
                  operations and increase efficiency. CAN Bus enabled systems
                  are common in multiple industries, we can offer a real-time
                  view to the data available on these networks.
                </>
              ),
            },
          ]}
        />

        {/* Slider Section */}
          <ImageSlider
            slides={[
              {
                backgroundImage:
                  "/Content/images/products/telematics/proemioncc.png",
                modalImage:
                  "/Content/images/products/telematics/proemioncc.png",
              },
              {
                backgroundImage:
                  "/Content/images/products/telematics/demoviewer.png",
                modalImage:
                  "/Content/images/products/telematics/demoviewer.png",
              },
              {
                backgroundImage:
                  "/Content/images/products/telematics/demohdlviewer.png",
                modalImage:
                  "/Content/images/products/telematics/demohdlviewer.png",
              },
            ]}
          />
      </section>
      <div
        style={{
          backgroundImage:
            "url('/Content/images/theme/page-background-dark.png')",
        }}
        className="py-4 w-full"
      >
        <div className="container mx-auto text-center">
          <p className="text-lg text-white inline-block mr-2">
            Start planning your custom solution today!
          </p>
          <a
            href="/contact"
            className="inline-block px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-gray-700"
          >
            Contact Us
          </a>
        </div>
      </div>

      <section className="flex flex-col md:flex-row justify-between items-start p-10 w-full max-w-7xl">
        <TextSection
          title="Android and iOS Solutions"
          paragraphs={[
            {
              subtitle: (
                <>
                  Loki Tracker <small>for Android + iPhone</small>
                </>
              ),
              content: (
                <>
                  <h3 className="text-md text-blue-400 font-semibold">
                    Update: Loki Tracker for Android now supports live video
                    streaming!
                  </h3>
                  It functions as a personal navigator with a map display and a
                  compass. The map can also be a photo layer. It also shows the
                  location of any “friendly forces”, or other tracked entities
                  within your user group. The augmented reality feature shows
                  the locations of nearby trackers using the camera and compass
                  features of the smart phone.
                </>
              ),
            },
            {
              subtitle: (
                <>
                  Loki GPS Logger <small>for iPhone</small>
                </>
              ),
              content: (
                <>
                  <p className="">
                    This app is suitable for tracking for work, or for personal
                    travel, all records remain stored on your phone with no 3rd
                    party access. Send others a copy of your location log via
                    email or SMS, view your location history on a map, and share
                    individual records.
                  </p>
                  <a href="https://apps.apple.com/us/app/loki-tracker/id1102944990">
                    <img
                      src="/Content/images/icons/Download_on_the_App_Store_Badge_US-UK_135x40.svg"
                      alt="Download on App Store"
                    />
                  </a>
                </>
              ),
            },
          ]}
        />

        <ImageSliderSection
          slides={[
            {
              backgroundImage:
                "/Content/images/products/mobile/LiveStream_thumb.jpg",
              modalImage: "/Content/images/products/mobile/LiveStream.jpg",
            },
            {
              backgroundImage:
                "/Content/images/products/mobile/LokiGPSLogger_thumb.png",
              modalImage: "/Content/images/products/mobile/LokiGPSLogger.png",
            },
            {
              backgroundImage:
                "/Content/images/products/mobile/blueforce_1_thumb.jpg",
              modalImage: "/Content/images/products/mobile/blueforce_1.jpg",
            },
          ]}
        />
      </section>
    </div>
  );
}
function ImageSliderSection({ slides, actionButton }) {
  return (
    <div className="w-full md:flex-1 pr-5 min-h-[300px] flex flex-col items-center">
      <div className="w-full max-w-xl">
        <ImageSlider slides={slides} />
      </div>

      {actionButton && (
        <a
          href={actionButton.link}
          className="mt-4 px-8 py-3 md:px-20 md:py-5 bg-red-500 text-white rounded-md hover:bg-gray-700 text-lg w-full md:w-auto"
        >
          {actionButton.label}
        </a>
      )}
    </div>
  );
}


function TextSection({ title, paragraphs, list }) {
  return (
    <div className="flex-1 bg-white p-8 shadow-md rounded-lg max-w-3xl">
      <h2 className="text-3xl font-bold">{title}</h2>
      {paragraphs &&
        paragraphs.map((p, index) => (
          <div key={index} className="mt-2">
            {p.subtitle && <h4 className="mt-2 text-xl font-semibold">{p.subtitle}</h4>}
            {typeof p.content === "string" ? <p>{p.content}</p> : p.content}
          </div>
        ))}
      {list && (
        <ul className="list-disc list-inside">
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Loki;
